import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import $ from 'jquery';
export default {
  name: 'scada-tree',
  data: function data() {
    return {
      title: '请确认是否继续刷新配置操作？',
      eblock: '',
      eLink: {},
      placement: 'right',
      switchConfirmShow: false,
      elinkDataShow: false,
      dischargeDepth: {
        dischargeDepth_U: 100,
        dischargeDepth_L: 0
      },
      keyboard: false,
      eblockSeterShow: false,
      checked1: true,
      checked2: false,
      expand: true,
      expandId: null,
      expandNode: null,
      data: [],
      equipNode: null,
      ds: {
        'id': 1,
        'name': '储能电站',
        'open': true,
        'children': []
      },
      timer_4: null
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.getEquipmentTreeFn();
    this_.$erd.listenTo(document.getElementById("out"), function (element) {
      if (this_.$route.path === '/energy_chain') {
        this_.reflush(true);
      }
    });

    window.onresize = function () {
      if (this_.$route.path === '/energy_chain') {
        this_.reflush(true);
      }
    };
  },
  destroyed: function destroyed() {
    clearInterval(this.timer_4);
  },
  methods: {
    showConfirm: function showConfirm(equipNode) {
      this.title = '请再次确认是否继续' + (equipNode.state > 1 ? '关闭' : '打开') + '设备：' + equipNode.num + (equipNode.state == 2 ? '能量链？' : '能量块？');

      if (equipNode.state > 1) {
        this.title = this.title + '';
      }

      this.switchConfirmShow = true;
      this.equipNode = equipNode;
    },
    closeSwitchConfirmShow: function closeSwitchConfirmShow() {
      this.switchConfirmShow = false;
    },
    openOrClose: function openOrClose() {
      clearInterval(this.timer_4);
      var this_ = this;
      this.ds.children.onoff ? 2 : 1;
      var data = {
        paramName: "onoff",
        data: this_.equipNode.state > 1 ? 1 : 2
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/system/param/' + this_.equipNode.id,
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '1') {
          this_.$message.success(this_.equipNode.num + (this_.equipNode.state == 2 ? '能量链？' : '能量块？') + (this_.equipNode.state > 1 ? '关机' : '开机') + '命令下发成功！');
          this_.equipNode.stateDisabled = true;
          this_.equipNode.state = -1;
          this_.switchConfirmShow = false;
        } else if (d.data.code === '401') {
          this_.$message.warning('当前账户没有开关机操作权限');
          this_.switchConfirmShow = false;
        } else {
          this_.$message.error(this_.equipNode.num + (this_.equipNode.state == 2 ? '能量链？' : '能量块？') + (this_.equipNode.state > 1 ? '关机' : '开机') + '命令下发失败！');
          this_.switchConfirmShow = false;
        }

        this_.timer_4 = window.setInterval(function () {
          this_.reflushData();
        }, 3000);
      }).catch(function (error) {
        this_.switchConfirmShow = false;
        this_.timer_4 = window.setInterval(function () {
          this_.reflushData();
        }, 3000);

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    showDrawer: function showDrawer() {
      this.elinkDataShow = true;
    },
    onClose: function onClose() {
      this.elinkDataShow = false;
    },
    onChange: function onChange() {},
    confirmSetting: function confirmSetting() {},
    setstate: function setstate(data) {
      var this_ = this;

      if (data.type == 2) {
        this_.ds.children.map(function (item) {
          if (item.id == data.id) {
            if (data.power == 0) {
              if (data.state == 1) {
                item.state = 2;
                item.stateDisabled = false;
              } else {
                item.state = -1;
                item.stateDisabled = true;
              }
            } else {
              item.state = 2;
              item.stateDisabled = false;
            }

            item.loading = false;
            item.stateDisabled = true;
          }
        });
      } else if (data.type == 3) {
        this_.ds.children.map(function (item) {
          if (item.id == this_.expandId) {
            item.children.map(function (x) {
              if (data.id == x.id) {
                x.state = data.state;
                x.loading = false;

                if (data.state == -1) {
                  x.stateDisabled = true;
                } else {
                  x.stateDisabled = false;
                }
              }
            });
          }
        });
      }
    },
    reflushData: function reflushData() {
      var this_ = this;
      var ids = '';

      for (var i = 0; i < this_.data.length; i++) {
        ids = ids + this_.data[i].id + ',';
      }

      if (ids.indexOf(',') != -1) {
        ids = ids.substring(0, ids.length - 1);
        axios({
          method: 'post',
          data: {
            'param1': localStorage.getItem('stationId'),
            'param3': ids
          },
          url: '/emind/config/equipment/list/tree/data'
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === '0') {
            for (var i = 0; i < d.data.data.list.length; i++) {
              this_.setstate(d.data.data.list[i]);
              $('#power' + d.data.data.list[i].id).html(d.data.data.list[i].power / 10 + 'kW');
              $('#voltage' + d.data.data.list[i].id).html(d.data.data.list[i].voltage / 10 + 'V');
              $('#current' + d.data.data.list[i].id).html(d.data.data.list[i].current / 10 + 'A');
              $('#soc' + d.data.data.list[i].id).html(parseFloat(d.data.data.list[i].soc / 10) + '%'); //								if(d.data.data.list[i].capacity == 0){
              //									$('#soc' + d.data.data.list[i].id).html(parseFloat(d.data.data.list[i].soc / 10) + '%');
              //								} else {
              //									$('#soc' + d.data.data.list[i].id).html(parseFloat(d.data.data.list[i].batterySystemRemainingPower / d.data.data.list[i].capacity * 10).toFixed(2) + '%');
              //								}

              if (d.data.data.list[i].type == 3) {
                if (d.data.data.list[i].state === -1) {
                  $('#state' + d.data.data.list[i].id).html('离线');
                  $('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
                } else if (d.data.data.list[i].state === 0) {
                  $('#state' + d.data.data.list[i].id).html('停机');
                  $('#state' + d.data.data.list[i].id).css('color', '#ff2100');
                } else if (d.data.data.list[i].state === 1) {
                  $('#state' + d.data.data.list[i].id).html('停机');
                  $('#state' + d.data.data.list[i].id).css('color', '#ff2100');
                } else if (d.data.data.list[i].state === 2) {
                  $('#state' + d.data.data.list[i].id).html('待机');
                  $('#state' + d.data.data.list[i].id).css('color', '#409eff');
                } else if (d.data.data.list[i].state === 3) {
                  $('#state' + d.data.data.list[i].id).html('充电');
                  $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                } else if (d.data.data.list[i].state === 4) {
                  $('#state' + d.data.data.list[i].id).html('放电');
                  $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                } else if (d.data.data.list[i].state === 5) {
                  $('#state' + d.data.data.list[i].id).html('降额');
                  $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                } else if (d.data.data.list[i].state === 6) {
                  if (d.data.data.list[i].power > 0) {
                    $('#state' + d.data.data.list[i].id).html('放电');
                    $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                  } else if (d.data.data.list[i].power == 0) {
                    $('#state' + d.data.data.list[i].id).html('待机');
                    $('#state' + d.data.data.list[i].id).css('color', '#409eff');
                  } else if (d.data.data.list[i].power < 0) {
                    $('#state' + d.data.data.list[i].id).html('充电');
                    $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                  } else {
                    $('#state' + d.data.data.list[i].id).html('未知');
                    $('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
                  }
                } else if (d.data.data.list[i].state === 7) {
                  $('#state' + d.data.data.list[i].id).html('故障');
                  $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                } else {
                  $('#state' + d.data.data.list[i].id).html('离线');
                  $('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
                }
              } else if (d.data.data.list[i].type == 2) {
                if (d.data.data.list[i].power > 0) {
                  $('#state' + d.data.data.list[i].id).html('放电');
                  $('#state' + d.data.data.list[i].id).css('color', '#30cf00');
                } else if (d.data.data.list[i].power == 0) {
                  if (d.data.data.list[i].state == 1) {
                    $('#state' + d.data.data.list[i].id).html('待机');
                    $('#state' + d.data.data.list[i].id).css('color', '#409eff');
                  } else {
                    $('#state' + d.data.data.list[i].id).html('离线');
                    $('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
                  }
                } else if (d.data.data.list[i].power < 0) {
                  $('#state' + d.data.data.list[i].id).html('充电');
                  $('#state' + d.data.data.list[i].id).css('color', '#30cf00'); //									} else {
                  //										$('#state' + d.data.data.list[i].id).html('离线');
                  //										$('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
                }
              }
            }
          } else {
            this_.$mssage.error('请求出错', 2, function () {});
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      }
    },
    reflush: function reflush(flag) {
      var this_ = this;
      var i = 0;
      var ff = false;
      $('#eLinks').css('width', this_.ds.children.length * 190 + 'px');

      if (flag) {
        if (localStorage.getItem('eLinkId') == null) {
          if (this_.ds.children[0]) {
            this_.ds.children[0].open = true;
            this_.data = this_.ds.children[0].children;
            this_.expandId = parseInt(this_.ds.children[0].id, 10);
            this_.expandNode = this_.ds.children[0];
            localStorage.setItem('eLinkId', this_.expandId);
          }
        } else {
          for (; i < this_.ds.children.length; i++) {
            if (this_.ds.children[i].id == localStorage.getItem('eLinkId')) {
              ff = true;

              if (this_.ds.children[i]) {
                this_.ds.children[i].open = true;
                this_.data = this_.ds.children[i].children;
                this_.expandNode = this_.ds.children[i];
                this_.expandId = parseInt(this_.ds.children[i].id, 10);
                break;
              }
            }
          }
        }
      }

      if (!ff) {
        if (this_.ds.children[0]) {
          this_.ds.children[0].open = true;
          this_.data = this_.ds.children[0].children;
          this_.expandId = parseInt(this_.ds.children[0].id, 10);
          this_.expandNode = this_.ds.children[0];
          localStorage.setItem('eLinkId', this_.expandId);
        }
      }

      var windowWidth = $("#root").css("width").replace("px", "") * 1;
      var leafsWidth = this_.data.length * 190;

      if (leafsWidth > windowWidth) {
        if (this_.expand) {
          $('#leafs').css('width', this_.data.length * 190 + 'px');
          $('#leafs').css('margin-left', 0 + 'px');
          $('#out').css('overflow-x', 'scroll');
        } else {
          $('#out').css('overflow', 'hidden');
        }
      } else {
        var left = (windowWidth - this_.ds.children.length * 190) / 2 + i * 70 + 20;

        if (i > 0) {
          left += 50;
        }

        $('#leafs').css('width', this_.data.length * 190 + 'px');

        if (left - leafsWidth / 2 + 50 < 10) {
          $('#leafs').css('margin-left', (windowWidth - leafsWidth) / 2 + 'px');
        } else {
          if (i >= this_.ds.children.length / 2) {
            if (left * 2 > leafsWidth) {
              $('#leafs').css('margin-left', (windowWidth - leafsWidth) / 2 + 75 + 'px');
            } else {
              $('#leafs').css('margin-left', 10 + 'px');
            }
          } else {
            if (leafsWidth / 2 + left < windowWidth) {
              $('#leafs').css('margin-left', left - leafsWidth / 2 + 75 + 'px');
            } else {
              $('#leafs').css('margin-left', windowWidth - leafsWidth + 10 + 'px');
            }
          }

          if (leafsWidth + $('#leafs').css('margin-left').replace("px", "") * 1 > windowWidth) {
            $('#leafs').css('margin-left', windowWidth - leafsWidth - 10 + 'px');
          }
        }
      }
    },
    getEquipmentTreeFn: function getEquipmentTreeFn() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/list/1/' + localStorage.getItem('stationId')
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.data = [];
        this_.ds.children = [];
        var i = 0;

        if (d.data.code === '0') {
          this_.ds.children = [];

          for (var i = 0; i < d.data.data.length; i++) {
            if (d.data.data[i].ntype == 1 && d.data.data[i] != null && d.data.data[i].children != null) {
              for (var j = 0; j < d.data.data[i].children.length; j++) {
                this_.ds.children.push(d.data.data[i].children[j]);
              }
            } else if (d.data.data[i].ntype == 2) {
              this_.ds.children.push(d.data.data[i]);
            }
          }

          this_.ds.children.sort(this_.sortId);

          if (localStorage.getItem('eLinkId') == null) {
            if (this_.ds.children.length > 0) {
              localStorage.setItem('eLinkId', this_.ds.children[0].id);
            }
          }

          this_.reflush(true);
          this_.expand = true;
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }

        if (this_.timer_4 == null) {
          this_.reflushData();
          this_.timer_4 = window.setInterval(function () {
            this_.reflushData();
          }, 3000);
        }

        this_.data.sort(this_.sortId);
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    sortId: function sortId(a, b) {
      return (a.num - b.num) * 1;
    },
    toDetial: function toDetial(e, param, action) {
      var this_ = this;

      if (param.ntype == 3) {
        localStorage.setItem('eblockId', parseInt(param.id, 10));

        if (action == 1) {
          this_.$router.push({
            path: 'eblock'
          }).catch(function (param) {});
          var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
          var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {
            return key.indexOf('date') >= 0 ? new Date(value) : value;
          });

          if (breadcrumbs.length > 1) {
            breadcrumbs.splice(1, 1);
          }

          if (this_.expandNode != null) {
            breadcrumbs.push({
              name: this_.expandNode.name,
              url: "/energy_chain"
            });
          }

          breadcrumbs.push({
            name: param.name,
            url: ""
          });
          this_.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
            root: true
          });
        } else {
          this_.eblock = param.name;
          this_.eblockSeterShow = true;
        }
      } else if (param.ntype == 2) {
        localStorage.setItem('eLinkId', parseInt(param.id, 10));

        if (action == 2) {
          this_.$router.push({
            path: 'device-settings'
          }).catch(function (param) {});
          var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
          var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {
            return key.indexOf('date') >= 0 ? new Date(value) : value;
          });

          if (breadcrumbs.length > 1) {
            breadcrumbs.splice(1, 1);
          }

          breadcrumbs.push({
            name: param.name,
            url: "/energy_chain"
          });
          breadcrumbs.push({
            name: "策略设置",
            url: ""
          });
          this_.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
            root: true
          });
        } else {
          this_.eLink = this.expandNode;
          this_.showDrawer();
        }
      } else {
        var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
        var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {
          return key.indexOf('date') >= 0 ? new Date(value) : value;
        });

        if (breadcrumbs.length > 1) {
          breadcrumbs.splice(1, 1);
        }

        if (this_.expandNode != null) {
          breadcrumbs.push({
            name: "储能电站",
            url: "/energy_chain"
          });
        }

        breadcrumbs.push({
          name: "电表数据",
          url: ""
        });
        this_.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
          root: true
        });
        this_.$router.push({
          path: 'meter'
        }).catch(function (param) {});
      }
    },
    handNode: function handNode(e, param) {
      var this_ = this;
      this_.expandNode = param;
      this_.data = param.children;
      this_.data.sort(this_.sortId);
      var siderWidth = $('.sider')[0].offsetWidth;
      var left = e.currentTarget.getBoundingClientRect().left - siderWidth + $(window).scrollLeft();
      var windowWidth = $("#root").css("width").replace("px", "") * 1;
      var leafsWidth = this_.data.length * 190;

      if (this_.expand) {
        if (this_.expandId == param.id) {
          for (var i = 0; i < this_.ds.children.length; i++) {
            if (this_.ds.children[i].id === '' + param.id) {
              this_.ds.children[i].open = false;
            }
          }

          this_.expandId = -1;
          localStorage.setItem('eLinkId', this_.expandId);
          this_.expand = false;
          param.open = false;
        } else {
          for (var i = 0; i < this_.ds.children.length; i++) {
            if (this_.ds.children[i].id === '' + this_.expandId) {
              this_.ds.children[i].open = false;
            }
          }

          this_.expandId = param.id;
          localStorage.setItem('eLinkId', this_.expandId);
          this_.expand = true;
          param.open = true;
          this_.reflushData();
        }
      } else {
        this_.expandId = param.id;
        localStorage.setItem('eLinkId', this_.expandId);

        for (var i = 0; i < this_.ds.children.length; i++) {
          if (this_.ds.children[i].id === '' + param.id) {
            this_.ds.children[i].open = true;
          }
        }

        this_.expand = true;
        this_.reflushData();
      }

      if (!this_.expand) {
        this_.expandNode = null;
      }
    }
  }
};